import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { compact, filter, get, uniqBy } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import TileCard from '../../components/TileCard';
import Dropdown, { OptionType } from '../../components/Dropdown';
import Divider from '../../components/Divider';
import InfiniteScroll from '../../components/InfiniteScroll';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import SEO from '../../components/SEO/SEO';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { EventsIndexPageQuery, EventFragment, Scalars } from '../../../graphql.schema';
import { GlobalPageContext, PaginationContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './events-index.module.scss';

type PageContextType = GlobalPageContext &
  PaginationContext & {
    eventIds: Scalars['ID'][];
  };

type FilteredByParamsType = {
  locations: OptionType[];
  schools: OptionType[];
}

const EventsIndex: React.FC<PageProps<EventsIndexPageQuery, PageContextType>> = ({ data: { strapi }, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const { cities, schools } = strapi;

  const intl = useIntl();
  const events = get(strapi, 'events', []);
  const pages = get(strapi, 'pages', [])
  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedSchools, setSelectedSchools] = useState<string[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<EventFragment[]>([]);
  const [list, setEventsList] = useState(events);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState<boolean>(false);
  const [filteredByParams, setFilteredValueByParams] = useState<FilteredByParamsType>({
    locations: [],
    schools: [],
  })
  if (!websiteLocale || !events) return <p>No events</p>;

  const uniqueEventTypes = [
    ...new Set(
      compact(events)
        .map(({ categories }) => compact(categories).map(({ name }) => name))
        .flat(),
    ),
  ];


  useEffect(() => getEventsBasedOnParams(), [JSON.stringify(cities), JSON.stringify(schools)]);

  const getEventsBasedOnParams = () => {
    const params = new URLSearchParams(window.location.search);
    const schoolId = new Set(params.getAll('school'));
    const locationId = new Set(params.getAll('city'));

    //Update and show dropdown selected value as per params.
    const { locations: locationsByParams, schools: schoolsByParams }: FilteredByParamsType = {
      locations: [],
      schools: []
    };

    cities?.forEach(city => {
      const locationName: string = get(city, 'name', '');
      if (locationId.has(get(city, 'slug', '') ?? '')) {
        locationsByParams.push({ value: locationName, label: locationName })
      }
    })
    schools?.forEach(school => {
      const schoolName: string = get(school, 'name', '');
      if (schoolId.has(get(school, 'slug', '') ?? '')) {
        schoolsByParams.push({ value: schoolName, label: schoolName })
      }
    })
    setFilteredValueByParams({
      locations: locationsByParams,
      schools: schoolsByParams
    })
    setSelectedLocations(locationsByParams.map(location => location.value));
    setSelectedSchools(schoolsByParams.map(school => school.value));
  }

  useEffect(() => {
    setEventsList(events.slice(0, 10));
    setIsInitialLoadDone(true);
  }, [events]);

  useEffect(() => {
    let filtered = compact(events);

    if (selectedEventTypes.length > 0) {
      filtered = filtered.filter((event) =>
        compact(event.categories).some((e) => selectedEventTypes.includes(e.name ?? '')),
      );
    }

    if (selectedLocations.length > 0) {
      filtered = filtered.filter((event) => compact(selectedLocations).includes(event.city?.name ?? ''));
    }

    if (selectedSchools.length > 0) {
      filtered = filtered.filter((event) => compact(selectedSchools).includes(event.school?.name ?? ''));
    }
    setFilteredEvents(filtered.slice(0, 10));
  }, [selectedEventTypes, selectedLocations, selectedSchools]);

  const getFilteredEvents = useMemo(() => {
      const filterByCategory = selectedEventTypes.length ? filter(compact(events), (event: EventFragment) =>
      compact(event?.categories).some((cat) => selectedEventTypes.includes(cat.name ?? '')),
      ) : events;
     const filterByLocation = selectedLocations.length ? filter(compact(filterByCategory), (event: EventFragment) =>
      compact(selectedLocations).includes(event.city?.name ?? ''),
      ) : filterByCategory;
     const filterBySchool = selectedSchools.length ?  filter(compact(filterByLocation), (event: EventFragment) =>
      compact(selectedSchools).includes(event.school?.name ?? ''),
      ) : filterByLocation;
    return uniqBy([...filterBySchool], 'documentId');
  }, [events, selectedEventTypes, selectedLocations, selectedSchools]);

  const currentEvents = useMemo(() => {
    if (selectedEventTypes.length > 0 || selectedLocations.length > 0 || selectedSchools.length > 0) {
      return filteredEvents;
    }
    return compact(list);
  }, [list, filteredEvents]);
  const handleDropdownChange = (val: OptionType[], type: string) => {
    const valueStrings = val.map((v) => v.value);
    switch (type) {
      case 'event-type':
        setSelectedEventTypes([...valueStrings]);
        break;
      case 'location-type':
        setSelectedLocations([...valueStrings]);
        break;
      case 'school-type':
        setSelectedSchools([...valueStrings]);
        break;
      default:
        return null;
    }
  };
  const showFooterCta = pages[0]?.pageSettings?.showFooterCta ?? false;
  const footerCta = pages[0]?.pageSettings?.customFooterCta?.documentId
    ? pages[0]?.pageSettings?.customFooterCta
    : websiteLocale.footerCta;
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={pages[0]?.seo?.metaTitle ?? pages[0]?.pageSettings?.title ?? intl.formatMessage({ id: 'seo.events.title' })}
        description={pages[0]?.seo?.metaTitle ?? pages[0]?.pageSettings?.metaDescription ?? intl.formatMessage({ id: 'seo.events.description' })}
        image={pages[0]?.seo?.metaImage?.url}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
        currentPage={pageContext}
      />
      <div className={classnames('container', styles.root)}>
        <h1 className="title color-a">
          <FormattedMessage id="eventsIndexHeading" />
        </h1>
        <Divider paddingTop="75px" marginBottom="25px" />
        <div className="is-flex-desktop">
          <Dropdown
            placeholder={intl.formatMessage({ id: 'placeholderEventType', defaultMessage: 'Event Type' })}
            options={compact(uniqueEventTypes).map((name) => ({ label: name, value: name }))}
            onChange={(val) => handleDropdownChange(val, 'event-type')}
          />
          <Dropdown
            placeholder={intl.formatMessage({ id: 'placeholderLocation', defaultMessage: 'Location' })}
            options={compact(cities).map(({ name }) => ({ label: name, value: name }))}
            onChange={(val) => handleDropdownChange(val, 'location-type')}
            filteredValueByParams={filteredByParams.locations}
          />
          <Dropdown
            placeholder={intl.formatMessage({ id: 'placeholderSchoolType', defaultMessage: 'School' })}
            options={compact(schools).map(({ name }) => ({ label: name, value: name }))}
            onChange={(val) => handleDropdownChange(val, 'school-type')}
            filteredValueByParams={filteredByParams.schools}
          />
        </div>
        {filteredEvents.length < 1 ? (
          <div className={classnames('column is-5 p-0 mt-10', styles.noVacancies)}>
            <h4 className="title is-4">
              <FormattedMessage id="eventsIndexHeading" defaultMessage="No current upcoming events" />
            </h4>
            <p>
              <FormattedMessage
                id="noEventsIndexDescription"
                defaultMessage="We’re not currently hiring for any positions, please check back soon."
              />
            </p>
          </div>
        ) : null}
        <div className={styles.gridWrapper}>
          {selectedEventTypes.length > 0 || selectedLocations.length > 0 || selectedSchools.length > 0
            ? compact(filteredEvents).map(({ documentId, title, slug, eventTime, eventAddress, image, categories }) => {
              if (!slug) return null;
              return (
                <TileCard
                  linkText={intl.formatMessage({ id: 'eventsCardText', defaultMessage: 'Learn more and RSVP' })}
                  slugBase={pageContext.pathPrefixes.events}
                  key={`event-${documentId}`}
                  variant="event"
                  title={title ?? 'N/A'}
                  slug={slug}
                  image={image?.url}
                  categories={compact(categories)}
                  date={eventTime}
                  address={eventAddress ?? ''}
                  selectedCategories={selectedEventTypes}
                />
              );
            })
            : currentEvents.map(({ documentId, title, slug, eventTime, eventAddress, image, categories }) => {
              if (!slug) return null;
              return (
                <TileCard
                  linkText={intl.formatMessage({ id: 'eventsCardText', defaultMessage: 'Learn more and RSVP' })}
                  slugBase={pageContext.pathPrefixes.events}
                  key={`event-${documentId}`}
                  variant="event"
                  title={title ?? 'N/A'}
                  slug={slug}
                  image={image?.url}
                  categories={compact(categories)}
                  date={eventTime}
                  address={eventAddress ?? ''}
                  selectedCategories={selectedEventTypes}
                />
              );
            })}
        </div>
        {isInitialLoadDone && (
          <InfiniteScroll
            selectedCategories={[...selectedEventTypes, ...selectedLocations, ...selectedSchools]}
            categoryList={getFilteredEvents}
            filteredList={currentEvents}
            completeList={events}
            list={currentEvents}
            setList={(list) => setEventsList(list)}
            setFilteredList={(filteredList) => setFilteredEvents(filteredList)}
          />
        )}
      </div>
      <div className={classnames('page-components')}>
        {pages[0]?.components &&
          pages[0]?.components?.map((component: any, idx: any) =>
            component ? (
              <ComponentRenderer
                hasRTL={websiteLocale?.hasRightToLeftLanguage}
                component={component}
                key={`page-component-${component.__typename}-${idx}`}
              />
            ) : null,
          )}
      </div>
      {footerCta && showFooterCta && <StrapiFooterCtaComponent {...footerCta} />}
    </LayoutWrapper>
  );
};

export const query = graphql`
fragment EventCard on Strapi_Event {
  slug
  title
  eventTime: event_time
  school {
    data {
      documentId
      attributes {
        name
      }
    }
  }
  city {
    data {
      documentId
      attributes {
        name
      }
    }
  }
  eventTime: event_time
  eventAddress: event_address
  categories {
    data {
      documentId
      attributes {
        name
      }
    }
  }
  image {
    data {
      documentId
      attributes {
        name
        alternativeText
        caption
        width
        height
        formats
        mime
        size
        url
        previewUrl
      }
    }
  }
}

query EventsIndexPage(
  $websiteLocaleId: ID!
  $eventIds: [ID!]
  $currentDate: Strapi_DateTime!
  $locale: Strapi_I18NLocaleCode
) {
  strapi {
    schools(
      filters: { events: { documentId: { in: $eventIds } } }
      locale: $locale
      pagination: { limit: 100 }
    ) {
      data {
        documentId
        attributes {
          name
        }
      }
    }
    cities(
      filters: { website_locale: { documentId: { eq: $websiteLocaleId } } }
      locale: $locale
      pagination: { limit: 100 }
    ) {
      data {
        documentId
        attributes {
          name
        }
      }
    }
    allEvents: events(
      filters: { documentId: { in: $eventIds } }
      locale: $locale
    ) {
      data {
        documentId
        attributes {
          ...EventCard
        }
      }
    }
    events(
      filters: {
        event_time: { gte: $currentDate }
        website_locale: { documentId: { eq: $websiteLocaleId } }
      }
      locale: $locale
      sort: "event_time:asc"
      pagination: { limit: 100 }
    ) {
      data {
        documentId
        attributes {
          title
          slug
          eventTime: event_time
          eventAddress: event_address
          content
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          categories {
            data {
              documentId
              attributes {
                name
              }
            }
          }
          image {
            data {
              documentId
              attributes {
                alternativeText
                caption
                width
                height
                formats
                url
                hash
              }
            }
          }
          school {
            data {
              documentId
              attributes {
                name
              }
            }
          }
          city {
            data {
              documentId
              attributes {
                name
              }
            }
          }
          embed_code
          link {
            ...LinkComponent
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
    pages(
      filters: {
        website_locale: { documentId: { eq: $websiteLocaleId } }
        index_page: { eq: "Events" }
      }
      locale: $locale
    ) {
      data {
        documentId
        attributes {
          title
          slug
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(EventsIndex));
